import {
  Button,
  ContentContainer,
  DocumentCheckbox,
  Header,
  Icon,
  PdfViewer,
  Popup,
} from '../../components/Generic'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import PrivacyPolicy from '../../assets/privacy_policy.pdf'
import PrivacyPolicyDe from '../../assets/privacy_policy_de.pdf'
import { StoreContext } from '../../components/App'
import {
  ButtonImgWrap,
  ButtonText,
  MicropaymentInfoContent,
  MicropaymentInfoGraph,
  MicropaymentWarn,
  MicropaymentWarnInner,
} from '../../styles/generic.styles'
import {
  JitpayGeneralConditionsSpacerMobile,
  WelcomeCheck,
} from '../../styles/jitpay.styles'
import {
  ContentContainerBottomFixed,
  ContentContainerBottomLeft,
} from '../../styles/layout.styles'
import { JitpayInfoGraph } from './JitpayInfoGraph'

export const MicropaymentInfoJitpayPageStandalone = observer(() => {
  const store = useContext(StoreContext)
  const { pageWidth, pageHeight } = store.AppState
  const { language } = store.TranslationsState
  const { currentPageIndex } = store.ScenarioState
  const { theme } = store.InterfaceState

  const { micropaymentInfoJitpayPage: trans } =
    store.TranslationsState.translations

  const [idData, setIdData] = useState(false)
  const [privPopupVisible, setPrivPopupVisible] = useState(false)
  const [mobileVersion, setMobileVersion] = useState(false)
  const [containerPdfViewerHeight, setContainerPdfViewerHeight] = useState('')

  useEffect(() => {
    if (pageWidth && pageWidth <= 980) {
      setContainerPdfViewerHeight(`calc(${window.innerHeight}px - 80px)`)
    }
  }, [pageWidth, pageHeight])

  const handleProceed = async () => {
    store.ScenarioState.setCurrentPage(currentPageIndex + 1)
  }

  useEffect(() => {
    if (pageWidth && pageWidth <= 980) {
      setMobileVersion(true)
    } else {
      setMobileVersion(false)
    }
  }, [pageWidth])

  const isTouchScrollDisabled = () => {
    if (privPopupVisible) {
      return 'none'
    } else {
      return 'auto'
    }
  }

  const handleIdData = ({ checked: value }) => {
    setIdData(value)
  }

  return (
    <>
      <ContentContainer
        {...theme.container}
        width="560px"
        paddingMobile="20px"
        marginMobile="0"
        touchAction={isTouchScrollDisabled()}
      >
        <Header {...theme.header} fontFamily={theme.globals.fontFamilyHeadline}>
          {trans.header}
        </Header>

        <MicropaymentInfoGraph>
          <JitpayInfoGraph />
        </MicropaymentInfoGraph>

        <MicropaymentInfoContent>{trans.content}</MicropaymentInfoContent>

        <MicropaymentWarn>
          <MicropaymentWarnInner>
            <Icon
              size="24px"
              type="alert-triangle"
              color="rgba(255, 143, 143, 1)"
            />
            <span>{trans.warning}</span>
          </MicropaymentWarnInner>
        </MicropaymentWarn>

        <WelcomeCheck margin="40px 0 0 0">
          <DocumentCheckbox
            inputName="id-data"
            description={trans.idData}
            externalUrl={''}
            handleDecision={handleIdData}
            value={idData}
            {...theme.documentCheckbox}
            margin="0 0 30px 0"
            contentFontsize="14px"
            iconSize="26px"
            onClick={() => setPrivPopupVisible(true)}
            tabIndex={privPopupVisible ? -1 : 0}
          />
        </WelcomeCheck>

        {mobileVersion ? <JitpayGeneralConditionsSpacerMobile /> : <></>}
        <ContentContainerBottomFixed>
          {!mobileVersion ? <ContentContainerBottomLeft /> : <></>}
          <Button
            onClick={() => handleProceed()}
            disabled={idData ? false : true}
            {...theme.button}
            width="164px"
            paddingMobile="14px 40px"
          >
            <>
              <ButtonImgWrap>
                <Icon size="18px" type="checkmark" />
              </ButtonImgWrap>
              <ButtonText>{trans.proceed}</ButtonText>
            </>
          </Button>
        </ContentContainerBottomFixed>
        {mobileVersion ? (
          <>
            <Popup
              visibility={privPopupVisible}
              handleVisibility={setPrivPopupVisible}
              {...theme.popup}
            >
              <PdfViewer
                file={language === 'de' ? PrivacyPolicyDe : PrivacyPolicy}
                {...theme.pdfViewer}
                viewerWidth="100%"
                viewerHeight={containerPdfViewerHeight}
                initialScale={1}
              />
            </Popup>
          </>
        ) : (
          <>
            <Popup
              visibility={privPopupVisible}
              handleVisibility={setPrivPopupVisible}
              {...theme.popup}
            >
              <PdfViewer
                file={language === 'de' ? PrivacyPolicyDe : PrivacyPolicy}
                {...theme.pdfViewer}
                viewerWidth="100%"
                viewerHeight={`calc(100vh - 405px)`}
                initialScale={1}
              />
            </Popup>
          </>
        )}
      </ContentContainer>
    </>
  )
})
