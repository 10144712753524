import {
  Button,
  ContentContainer,
  DocumentCheckbox,
  PdfViewer,
  Popup,
} from '../../components/Generic'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import PrivacyPolicy from '../../assets/privacy_policy.pdf'
import PrivacyPolicyDe from '../../assets/privacy_policy_de.pdf'
import tink from '../../assets/tink_logo.png'
import { StoreContext } from '../../components/App'
import {
  ButtonText,
  MicropaymentInfoContent,
} from '../../styles/generic.styles'
import {
  CenterLiner,
  LineHeight,
  MicropaymentInfoGraph,
  TinkInfo,
} from '../../styles/ikano.styles'
import { WelcomeCheck } from '../../styles/jitpay.styles'
import {
  ContentContainerBottomFixed,
  ContentContainerBottomLeft,
  MobileSpacerForContentContainerBottomFixed,
} from '../../styles/layout.styles'
import { IkanoInfoGraph } from './IkanoInfoGraph'

export const MicropaymentInfoIkanoPage = observer(() => {
  const [consent, setConsent] = useState(false)
  const [privPopupVisible, setPrivPopupVisible] = useState(false)

  const store = useContext(StoreContext)
  const { pageWidth, pageHeight } = store.AppState
  const { language } = store.TranslationsState
  const { currentPageIndex } = store.ScenarioState
  const { theme } = store.InterfaceState

  const {
    klarnaPrivacyPolicyPage: policyPage,
    micropaymentInfoIkanoPage: trans,
  } = store.TranslationsState.translations

  const [mobileVersion, setMobileVersion] = useState(false)
  const [containerPdfViewerHeight, setContainerPdfViewerHeight] = useState('')

  useEffect(() => {
    if (pageWidth && pageWidth <= 980) {
      setContainerPdfViewerHeight(`calc(${window.innerHeight}px - 80px)`)
    }
  }, [pageWidth, pageHeight])

  const handleProceed = async () => {
    store.ScenarioState.setCurrentPage(currentPageIndex + 1)
  }

  useEffect(() => {
    if (pageWidth && pageWidth <= 980) {
      setMobileVersion(true)
    }
  }, [pageWidth])

  const isTouchScrollDisabled = () => {
    if (privPopupVisible) {
      return 'none'
    } else {
      return 'auto'
    }
  }

  const handleConsent = ({ checked: value }) => {
    setConsent(value)
  }

  return (
    <ContentContainer
      {...theme.container}
      width="560px"
      paddingMobile="20px"
      marginMobile="0"
      touchAction={isTouchScrollDisabled()}
    >
      <MicropaymentInfoGraph>
        <IkanoInfoGraph />
      </MicropaymentInfoGraph>

      {trans.content.split('\n').map((item, i) => (
        <MicropaymentInfoContent key={i}>{item}</MicropaymentInfoContent>
      ))}

      <TinkInfo>
        <CenterLiner>
          <img src={tink} alt="tink logo" />
        </CenterLiner>
        {trans.tink}
      </TinkInfo>

      <WelcomeCheck mobilePadding="0 0 100px 0">
        <LineHeight height="141.99%">
          <DocumentCheckbox
            inputName="terms-checkbox"
            description={policyPage.consent}
            externalUrl={''}
            handleDecision={handleConsent}
            value={consent}
            {...theme.documentCheckbox}
            margin="0 0 30px 0"
            contentFontsize="12px"
            iconSize="26px"
            onClick={() => setPrivPopupVisible(true)}
            tabIndex={privPopupVisible ? -1 : 0}
          />
        </LineHeight>
      </WelcomeCheck>

      <ContentContainerBottomFixed>
        {!mobileVersion ? <ContentContainerBottomLeft /> : <div />}
        <Button
          onClick={() => handleProceed()}
          {...theme.button}
          disabled={!consent}
          width={mobileVersion ? '100%' : '164px'}
          paddingMobile="14px 40px"
        >
          <ButtonText>{trans.proceed}</ButtonText>
        </Button>
      </ContentContainerBottomFixed>

      <MobileSpacerForContentContainerBottomFixed />

      {mobileVersion ? (
        <>
          <Popup
            visibility={privPopupVisible}
            handleVisibility={setPrivPopupVisible}
            {...theme.popup}
          >
            <PdfViewer
              file={language === 'de' ? PrivacyPolicyDe : PrivacyPolicy}
              {...theme.pdfViewer}
              viewerWidth="100%"
              viewerHeight={containerPdfViewerHeight}
              initialScale={1}
            />
          </Popup>
        </>
      ) : (
        <>
          <Popup
            visibility={privPopupVisible}
            handleVisibility={setPrivPopupVisible}
            {...theme.popup}
          >
            <PdfViewer
              file={language === 'de' ? PrivacyPolicyDe : PrivacyPolicy}
              {...theme.pdfViewer}
              viewerWidth="100%"
              viewerHeight={`calc(100vh - 405px)`}
              initialScale={1}
            />
          </Popup>
        </>
      )}
    </ContentContainer>
  )
})
