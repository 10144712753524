import {
  Button,
  ContentContainer,
  DocumentCheckbox,
  PdfViewer,
  Popup,
} from '../../components/Generic'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import PrivacyPolicy from '../../assets/privacy_policy.pdf'
import PrivacyPolicyDe from '../../assets/privacy_policy_de.pdf'
import { StoreContext } from '../../components/App'
import { actionReport } from '../../methods/actionReport'
import {
  ButtonText,
  MicropaymentInfoContent,
} from '../../styles/generic.styles'
import {
  IkanoStyleWrap,
  LineHeight,
  MicropaymentInfoGraph,
} from '../../styles/ikano.styles'
import { WelcomeCheck } from '../../styles/jitpay.styles'
import {
  ContentContainerBottomFixed,
  ContentContainerBottomLeft,
  MobileSpacerForContentContainerBottomFixed,
} from '../../styles/layout.styles'
import { IkanoOpenbankingInfoGraph } from './IkanoOpenbankingInfoGraph'

export const MicropaymentInfoIkanoOpenBankingPage = observer(() => {
  const store = useContext(StoreContext)
  const { pageWidth, pageHeight } = store.AppState
  const { context, currentPageIndex } = store.ScenarioState
  const { theme } = store.InterfaceState

  const { language } = store.TranslationsState
  const {
    micropaymentInfoIkanoOpenBankingPage: trans,
    klarnaPrivacyPolicyPage: policyPage,
  } = store.TranslationsState.translations

  const [signed, setSigned] = useState(false)
  const [openBanking, setOpenBanking] = useState(false)

  // Popups visibility
  const [openBankingPopupVisible, setOpenBankingPopupVisible] = useState(false)

  // Mobile utilities
  const [isMobile, setIsMobile] = useState(false)
  const [containerPdfViewerHeight, setContainerPdfViewerHeight] = useState('')

  useEffect(() => {
    if (pageWidth && pageWidth <= 980) {
      setContainerPdfViewerHeight(`calc(${window.innerHeight}px - 80px)`)
      setIsMobile(true)
    }
  }, [pageWidth, pageHeight])

  const handleOpenBanking = ({ checked: value }) => {
    actionReport({
      type: 'event.onboarding-web.consent.OPEN_BANKING_CHECKED',
      payload: {},
    })
    setOpenBanking(value)
  }

  const handleProceed = async () => {
    store.ScenarioState.setCurrentPage(currentPageIndex + 1)
  }

  useEffect(() => {
    if (context?.termsAndConditionsPresent) {
      store.ScenarioState.setCurrentPage(currentPageIndex + 1)
    }
  }, [])

  useEffect(() => {
    if (openBanking === true) {
      setSigned(true)
      actionReport({
        type: 'event.onboarding-web.consent.CONSENT_ACCEPTED',
        payload: {},
      })
    } else {
      setSigned(false)
    }
  }, [openBanking])

  const isTouchScrollDisabled = () => {
    if (openBankingPopupVisible) {
      return 'none'
    } else {
      return 'auto'
    }
  }

  useEffect(() => {
    if (openBankingPopupVisible === true) {
      actionReport({
        type: 'event.onboarding-web.consent.OPEN_BANKING_DISPLAYED',
        payload: {},
      })
    }
  }, [openBankingPopupVisible])

  return (
    <ContentContainer
      {...theme.container}
      width="560px"
      paddingMobile="20px"
      marginMobile="0"
      touchAction={isTouchScrollDisabled()}
    >
      <IkanoStyleWrap>
        <>
          <MicropaymentInfoGraph>
            <IkanoOpenbankingInfoGraph />
          </MicropaymentInfoGraph>

          {trans.content.split('\n').map((item, i) => (
            <MicropaymentInfoContent key={i}>{item}</MicropaymentInfoContent>
          ))}

          {/* Open Banking */}
          <WelcomeCheck margin="0 0 10px 0">
            <LineHeight height="141.99%">
              {/* <ConditionTitle required asteriskColor={theme.globals.inputRequired.asteriskColor}>{'Open Banking'}</ConditionTitle> */}
              <DocumentCheckbox
                inputName="iddata-checkbox"
                description={policyPage.consent}
                externalUrl={''}
                handleDecision={handleOpenBanking}
                value={openBanking}
                {...theme.documentCheckbox}
                margin="0 0 30px 0"
                contentFontsize="14px"
                iconSize="20px"
                onClick={() => setOpenBankingPopupVisible(true)}
                tabIndex={openBankingPopupVisible ? -1 : 0}
              />
            </LineHeight>
          </WelcomeCheck>

          <ContentContainerBottomFixed>
            {!isMobile ? <ContentContainerBottomLeft /> : <div />}
            <Button
              onClick={() => handleProceed()}
              {...theme.button}
              disabled={!signed}
              minWidth="224px"
              paddingMobile="14px 40px"
            >
              <ButtonText>{trans.proceed}</ButtonText>
            </Button>
          </ContentContainerBottomFixed>

          <MobileSpacerForContentContainerBottomFixed />

          {isMobile ? (
            <>
              <Popup
                visibility={openBankingPopupVisible}
                handleVisibility={setOpenBankingPopupVisible}
                {...theme.popup}
              >
                <PdfViewer
                  file={language === 'de' ? PrivacyPolicyDe : PrivacyPolicy}
                  {...theme.pdfViewer}
                  viewerWidth="100%"
                  viewerHeight={containerPdfViewerHeight}
                  initialScale={1}
                />
              </Popup>
            </>
          ) : (
            <>
              <Popup
                visibility={openBankingPopupVisible}
                handleVisibility={setOpenBankingPopupVisible}
                {...theme.popup}
              >
                <PdfViewer
                  file={language === 'de' ? PrivacyPolicyDe : PrivacyPolicy}
                  {...theme.pdfViewer}
                  viewerWidth="100%"
                  viewerHeight={`calc(100vh - 405px)`}
                  initialScale={1}
                />
              </Popup>
            </>
          )}
        </>
      </IkanoStyleWrap>
    </ContentContainer>
  )
})
